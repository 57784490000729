export const DEFAULT_ROWS_PER_PAGE_ITEMS = [
    50,
    100,
    250,
    {
        text: '$vuetify.dataIterator.rowsPerPageAll',
        value: -1
    }
];
export const DEFAULT_MODAL_ROWS_PER_PAGE_ITEMS = [
    10,
    20,
    50,
    {
        text: '$vuetify.dataIterator.rowsPerPageAll',
        value: -1
    }
];
