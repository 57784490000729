var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.computedColor,
      "hide-title-section": "",
      "modal-width": "95vw"
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [!_vm.parentAssemblyTemplateId ? _c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateAssemblyTemplate
          }
        }, [_vm._v(" Create " + _vm._s(_vm.subTypeDisplay) + " ")]) : _c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateAssemblyTemplate
          }
        }, [_vm._v(" Attach child ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "content"
  }, [!_vm.parentAssemblyTemplateId ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.ERROR
    }
  }, [_vm._v("No parent found. Please close this and try again.")]) : _vm._e(), _vm.parentAssemblyTemplateId ? _c('div', {
    staticClass: "mb-4"
  }, [_c('MiniAssemblyTemplate', {
    staticClass: "parent_mini",
    attrs: {
      "id": _vm.parentAssemblyTemplateId,
      "show-children-initially": false,
      "title": "Child will be attached to"
    }
  }), _c('v-divider', {
    staticClass: "my-2"
  })], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "Name",
      "clearable": "",
      "tooltip": "This will be the display of the node and serve as it's alias",
      "tooltip-position": "top",
      "autocomplete": "off"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), !_vm.isParentPbsAssemblyTemplate ? _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "enum": _vm.Side,
      "display": _vm.SideDisplay,
      "sort": false,
      "chips": false,
      "messages": _vm.installedOnMessage,
      "label": "Install on",
      "required": ""
    },
    model: {
      value: _vm.formData.installedOn,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "installedOn", $$v);
      },
      expression: "formData.installedOn"
    }
  })], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "PBS ID",
      "clearable": "",
      "messages": "Unique identifier"
    },
    model: {
      value: _vm.formData.pbsItemId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "pbsItemId", $$v);
      },
      expression: "formData.pbsItemId"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-3": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "error-messages": _vm.sequenceNumberErrorMessage,
      "messages": _vm.sequenceNumberMessage,
      "autofocus": "",
      "label": "Sequence",
      "required": "",
      "tooltip-position": "right",
      "tooltip": "This is how you order this node with respect to it's siblings"
    },
    model: {
      value: _vm.formData.sequence,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "sequence", $$v);
      },
      expression: "formData.sequence"
    }
  })], 1), !_vm.isParentPbsAssemblyTemplate ? _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-1": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Min qty",
      "max": _vm.formData.maxQuantity,
      "pre-computed-rules": [_vm.minIsLessThanMax]
    },
    model: {
      value: _vm.formData.minQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "minQuantity", $$v);
      },
      expression: "formData.minQuantity"
    }
  })], 1) : _vm._e(), !_vm.isParentPbsAssemblyTemplate ? _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-3": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Max qty",
      "pre-computed-rules": [_vm.minIsLessThanMax]
    },
    model: {
      value: _vm.formData.maxQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "maxQuantity", $$v);
      },
      expression: "formData.maxQuantity"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.showPartReferenceSelector ? _c('div', {
    staticClass: "my-2"
  }, [_c('ButtonCreate', {
    staticClass: "mb-2",
    attrs: {
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.showPartReferenceSelector = true;
      }
    }
  }, [_vm._v("Add part")]), _c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "headers": _vm.PartReferenceTableHeaders,
      "icon": _vm.$icons.part_reference,
      "items": _vm.partReferenceTableItems,
      "title": "Parts"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('ButtonMiniActionRemoveFromList', {
          on: {
            "click": function click($event) {
              return _vm.onRemovalOfPartReference(item);
            }
          }
        })];
      }
    }], null, false, 1038714857)
  })], 1) : _vm._e()]), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showPartReferenceSelector ? _c('div', {
    staticClass: "my-2"
  }, [_c('SelectorPartReference', {
    on: {
      "edited-part-reference-selected": _vm.onPartReferenceEdited,
      "new-part-reference-selected": _vm.onNewPartReferenceSelected,
      "part-reference-selected": _vm.onPartReferenceSelected
    }
  }), _c('div', {
    staticClass: "c-flex align-items-center mt-2"
  }, [_c('ButtonCreate', {
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.onClickOfAddPartReference
    }
  }, [_vm._v("Add part")]), _c('ButtonCancel', {
    attrs: {
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.showPartReferenceSelector = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e()])], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };