import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseButtonMiniActionImplementation from '~/nasa_ui/base/BaseButtonMiniActionImplementation';
import ButtonMiniAction from '~/nasa_ui/components/buttons/ButtonMiniAction/ButtonMiniAction.vue';
/**
 * Intended use is for a non destructive action such as removing a selected item from a list
 */
let ButtonMiniActionRemoveFromList = class ButtonMiniActionRemoveFromList extends Mixins(BaseButtonMiniActionImplementation) {
    to;
    get computedTooltip() {
        return this.tooltip || 'Remove from list';
    }
};
__decorate([
    Prop({
        type: String,
        default: 'var(--cosmic-text)'
    }),
    __metadata("design:type", Object)
], ButtonMiniActionRemoveFromList.prototype, "iconColor", void 0);
__decorate([
    Prop({
        type: String,
        default: '22px'
    }),
    __metadata("design:type", Object)
], ButtonMiniActionRemoveFromList.prototype, "iconSize", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ButtonMiniActionRemoveFromList.prototype, "to", void 0);
ButtonMiniActionRemoveFromList = __decorate([
    Component({
        components: { ButtonMiniAction }
    })
], ButtonMiniActionRemoveFromList);
export default ButtonMiniActionRemoveFromList;
