import { PhysicalInventoryAuditStatus, PhysicalInventoryAuditType } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { AdjustmentReasonDisplay, InventoryClassDisplay, PhysicalInventoryAuditTypeDisplay } from '~/nasa_ui/types';
import { dateDisplay } from '../../dates';
import { CosmicIcons } from '../../ui/CosmicIcons';
import { addAsBuiltNumberDisplay, addBinDisplay, addBuildingDisplay, addDateDisplays, addDescriptionDisplay, addDrawingNumberDisplay, addItemNumberDisplay, addLotNumberDisplay, addSerialNumberDisplay, addSideDisplay, addSizeDisplay, addSubTypeDisplay, addUnitCost, addUserDisplays } from '../displayMappers';
import { numberDisplay, quantityPlusMethodDisplay } from '../displays';
import { isNullOrUndefined } from '../isNullOrUndefined';
export const transformPhysicalInventoryAudit = (items) => {
    return (items
        .map(addBuildingDisplay)
        .map(addUserDisplays)
        .map(addDateDisplays)
        .map(addSubTypeDisplay(PhysicalInventoryAuditTypeDisplay))
        // ITEM_COUNT
        .map((item) => {
        item._itemCount =
            item.subType && item.subType === PhysicalInventoryAuditType.WALL_TO_WALL
                ? 'ALL'
                : numberDisplay(item.itemCount);
        return item;
    })
        // SAMPLE_SIZE
        .map((item) => {
        item._sampleSize =
            item.subType && item.subType === PhysicalInventoryAuditType.WALL_TO_WALL
                ? 'ALL'
                : numberDisplay(item.sampleSize);
        return item;
    })
        // STATUS / STATUS_ICON
        .map((item) => {
        const PiaStatusMap = new Map([
            [PhysicalInventoryAuditStatus.COMPLETE, 'Complete'],
            [PhysicalInventoryAuditStatus.CANCELED, 'Canceled'],
            [PhysicalInventoryAuditStatus.OPEN, 'Open']
        ]);
        item._status = item.status ? PiaStatusMap.get(item.status) : DEFAULT_DASH;
        // OPEN
        // COMPLETE
        // CANCELED
        const iconColors = {
            open: 'grey--text',
            complete: 'success--text',
            canceled: 'error--text'
        };
        item._statusIcon = `<i class="text-xs-center fa-2x ${CosmicIcons['pipeline_' + item.status.toLowerCase()]} ${iconColors[item.status.toLowerCase()]}" title="${item.status}"></i>`;
        return item;
    })
        // _NAME
        .map((item) => {
        if (!item?.building) {
            item._name = 'Unknown';
        }
        else {
            item._name = `${PhysicalInventoryAuditTypeDisplay.get(item.subType)} in ${item.building} on ${dateDisplay(item.createdDateTime)}`;
        }
        return item;
    }));
};
export const transformPhysicalInventoryAuditItem = (items) => {
    return (items
        .map(addBinDisplay)
        .map(addDrawingNumberDisplay)
        .map(addItemNumberDisplay)
        .map(addDescriptionDisplay)
        .map(addAsBuiltNumberDisplay)
        .map(addSerialNumberDisplay)
        .map(addLotNumberDisplay)
        .map(addSideDisplay)
        .map(addSizeDisplay)
        .map(addUnitCost)
        .map((item) => {
        // ADJUSTMENT DATA
        if (item.recordedQuantity === item.actualQuantity) {
            item._adjustmentData = `<div class="adjustment_data">
            <i class="success--text fa-2x ${CosmicIcons['pipeline_complete']}" title="No deviations recorded."></i>
          </div>`;
        }
        else if (item.actualQuantity === null || item.actualQuantity === undefined) {
            item._adjustmentData = `
            <div class="adjustment_data">
              <i class="grey--text fa-2x ${CosmicIcons['pipeline_open']}" title="In work."></i>
            </div>
          `;
        }
        else {
            item._adjustmentData = `
            <div class="adjustment_data">
              <div class="align-center">
                <i class="error--text fa-2x fa fa-not-equal d-inline-block" title="Deviation logged."></i>
                <p class="mb-0 ml-2 font-weight-bold d-inline-block">${AdjustmentReasonDisplay.get(item.adjustmentReason) || 'None Entered'}</p>
              </div>
            </div>
            
          `;
        }
        return item;
    })
        // SHELF LIFE EXP
        .map((item) => {
        const shelfExpDate = item.inventory?.itemInstance?.limitedLifeRemaining?.shelf?.valueDueDate;
        const isInAlertState = item.inventory?.itemInstance?.limitedLifeRemaining?.shelf?.alert || false;
        if (shelfExpDate) {
            item._shelfLifeExpirationDate = `<span title="Expired Shelf Life" class="${isInAlertState ? 'font-weight-bold error--text' : ''}">${dateDisplay(shelfExpDate)}</span>`;
        }
        else {
            item._shelfLifeExpirationDate = `<i class="text-xs-center warning--text fa-2x ${CosmicIcons['warning']}" title="No Shelf Life Exp."></i>`;
        }
        return item;
    })
        // BIN_QUANTITY
        .map((item) => {
        if ('inventory' in item) {
            const display = quantityPlusMethodDisplay(item.recordedQuantity, item.inventory?.itemMaster);
            item._binQty = `<strong class="monospace_font no-wrap">${display}</strong>`;
        }
        return item;
    })
        // ACTUAL_QUANTITY
        .map((item) => {
        if ('actualQuantity' in item) {
            const display = isNullOrUndefined(item.actualQuantity)
                ? DEFAULT_DASH
                : quantityPlusMethodDisplay(item.actualQuantity, item.inventory?.itemMaster);
            item._actualQty = `<strong class="monospace_font no-wrap">${display}</strong>`;
        }
        return item;
    })
        // _SUBTYPE
        .map((item) => {
        item._subType = item.inventory.subType
            ? InventoryClassDisplay.get(item.inventory.subType) || 'Multiple'
            : DEFAULT_DASH;
        return item;
    }));
};
