import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AS_BUILT_COL, BASE_DATE_COL, BASE_ICON_COL, BASE_TEXT_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, LOT_COL, SEQUENCE_COL, SERIAL_COL, SIDE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { AlertType } from '~/nasa_ui/types';
import { buildTree } from '~/nasa_ui/utils';
import { deleteAssemblyTemplate, getAssemblyTemplate } from '~/nasa_ui/utils/dal';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformAssemblyTemplateResponseWithChildrens } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let MiniAssemblyTemplate = class MiniAssemblyTemplate extends Mixins(ReadOnlyMixin) {
    isFetching = false;
    localShowChildren = false;
    selectedEntity = null;
    showDetail = false;
    showModalAssemblyTemplateClone = false;
    showModalAssemblyTemplateCreate = false;
    showModalAssemblyTemplateEdit = false;
    showModalAssemblyTemplateInfo = false;
    showModalAssemblyTemplateActualization = false;
    tableHeadersForPartsList = [
        composeTableHeader(SEQUENCE_COL, { width: '50px' }),
        composeTableHeader(BASE_ICON_COL, {
            text: 'Item type',
            value: '_entityIcon',
            noEscape: true
        }),
        composeTableHeader(DRAWING_NUMBER_COL, {
            value: '_partReference.drawingNumber'
        }),
        composeTableHeader(AS_BUILT_COL, {
            value: '_partReference._asBuiltNumber',
            noWrap: true
        }),
        composeTableHeader(SIDE_COL, {
            value: '_partReference._side'
        }),
        composeTableHeader(SERIAL_COL, {
            value: '_partReference._serialNumber'
        }),
        composeTableHeader(LOT_COL, {
            value: '_partReference._lotNumber'
        }),
        composeTableHeader(DESCRIPTION_COL, {
            value: '_partReference._description'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Location',
            value: '_partReference._location'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Birth',
            value: '_partReference._birthDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Usage expiry',
            value: '_partReference._shelfLifeExpirationDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Shelf expiry',
            value: '_partReference._usageLifeExpirationDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Last calibration',
            value: '_partReference._lastCalibrationDate'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Calibration #',
            value: '_partReference._calibrationNumber'
        })
    ];
    // #region Props
    allowChildCreation;
    allowEdit;
    allowInstall;
    clearableId;
    entity;
    id;
    parentInventoryId; // for actualization of a template
    showChildren;
    showManageOnlyOnRoot;
    showChildrenInitially;
    title;
    // #endregion
    get chevronIcon() {
        return this.shouldShowChildContainer ? this.$icons.chevron_up : this.$icons.chevron_down;
    }
    get computedId() {
        return this.selectedEntity?.id || this.id;
    }
    get hasAnyActions() {
        return true;
    }
    get hasChildren() {
        return this.myChildren.length > 0;
    }
    get isRoot() {
        return !this.transformedSelectedEntity?.parentId;
    }
    get myChildren() {
        if (!this.transformedSelectedEntity?.children) {
            return [];
        }
        const children = [...this.transformedSelectedEntity.children];
        children.sort(sortObjectNumericallyBy('sequence'));
        return children;
    }
    get parentAssemblyTemplateId() {
        return this.transformedSelectedEntity?.parentId;
    }
    get shouldShowChildContainer() {
        return this.showChildren && this.myChildren.length > 0 && this.localShowChildren;
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformAssemblyTemplateResponseWithChildrens([this.selectedEntity])[0] : null;
    }
    created() {
        this.$listen(`reload_${this.computedId}`, this.fetchEntity);
    }
    onClickOfCreateChildAssemblyTemplate() {
        this.showModalAssemblyTemplateCreate = true;
    }
    onClickOfDeleteAssemblyTemplate() {
        try {
            deleteAssemblyTemplate(this.computedId);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Template deleted.'
            });
            if (this.parentAssemblyTemplateId) {
                this.$message(`reload_${this.parentAssemblyTemplateId}`);
            }
            else {
                // youre deleting the root so navigate to the search page
                this.$router.push(`/hardware/assembly_templates/search`);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onClickOfViewInfoAssemblyTemplate() {
        this.showModalAssemblyTemplateInfo = true;
    }
    onClickOfActualizeAssemblyTemplate() {
        this.showModalAssemblyTemplateActualization = true;
    }
    onCloseOfModalAssemblyTemplateCreate() {
        this.fetchEntity(this.computedId);
        this.reset();
    }
    onCloseOfModalAssemblyTemplateEdit() {
        this.fetchEntity(this.computedId);
        this.reset();
    }
    onClickOfEditAssemblyTemplate() {
        this.showModalAssemblyTemplateEdit = true;
    }
    onClickOfShowChildren() {
        this.localShowChildren = !this.localShowChildren;
    }
    onTemplateActualization(installedItem) {
        this.reset();
        this.$message(`reload_${this.id}`);
        this.$message(`reload_${installedItem.id}`);
    }
    reset() {
        this.showModalAssemblyTemplateCreate = false;
        this.showModalAssemblyTemplateEdit = false;
        this.showModalAssemblyTemplateInfo = false;
        this.showModalAssemblyTemplateActualization = false;
    }
    emitRefreshParent() { }
    onShowChildrenInitiallyChange(val) {
        this.localShowChildren = val;
    }
    onEntityChange() {
        if (!this.entity) {
            return;
        }
        this.selectedEntity = { ...this.entity };
    }
    async fetchEntity(assemblyTemplateId) {
        if (!assemblyTemplateId) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await getAssemblyTemplate(assemblyTemplateId);
            this.selectedEntity = resp.data;
            this.selectedEntity.children = buildTree(this.selectedEntity.children || []);
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({ err });
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniAssemblyTemplate.prototype, "allowChildCreation", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniAssemblyTemplate.prototype, "allowEdit", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniAssemblyTemplate.prototype, "allowInstall", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", Object)
], MiniAssemblyTemplate.prototype, "clearableId", void 0);
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", Object)
], MiniAssemblyTemplate.prototype, "entity", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniAssemblyTemplate.prototype, "id", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniAssemblyTemplate.prototype, "parentInventoryId", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssemblyTemplate.prototype, "showChildren", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssemblyTemplate.prototype, "showManageOnlyOnRoot", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniAssemblyTemplate.prototype, "showChildrenInitially", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], MiniAssemblyTemplate.prototype, "title", void 0);
__decorate([
    Emit('refresh-parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniAssemblyTemplate.prototype, "emitRefreshParent", null);
__decorate([
    Watch('showChildrenInitially', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], MiniAssemblyTemplate.prototype, "onShowChildrenInitiallyChange", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniAssemblyTemplate.prototype, "onEntityChange", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], MiniAssemblyTemplate.prototype, "fetchEntity", null);
MiniAssemblyTemplate = __decorate([
    Component
], MiniAssemblyTemplate);
export default MiniAssemblyTemplate;
