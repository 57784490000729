var _a, _b, _c, _d, _e, _f, _g, _h;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types/swagger_types';
import BaseSelector from '~/nasa_ui/base/BaseSelector';
import { InventoryClassDisplay, ItemClassesWithoutMultiple, Maybe, SideDisplay } from '~/nasa_ui/types';
import { getPartReference } from '~/nasa_ui/utils';
import { transformPartReferenceResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
var PartReferenceTab;
(function (PartReferenceTab) {
    PartReferenceTab["NEW_PART_REFERENCE"] = "NEW_PART_REFERENCE";
    PartReferenceTab["PART_REFERENCE_SEARCH"] = "PART_REFERENCE_SEARCH";
    PartReferenceTab["SELECTED_PART_REFERENCE"] = "SELECTED_PART_REFERENCE";
})(PartReferenceTab || (PartReferenceTab = {}));
let SelectorPartReference = class SelectorPartReference extends BaseSelector {
    currentTab = PartReferenceTab.PART_REFERENCE_SEARCH;
    InventoryClassDisplay = InventoryClassDisplay;
    InventoryClassWithoutMultiple = ItemClassesWithoutMultiple;
    PartReferenceTab = PartReferenceTab;
    selectedPartReference = null;
    SideDisplay = SideDisplay;
    Side = Side;
    newPartReferenceFormData = {
        attributes: {},
        asBuiltNumber: null,
        birthDate: null,
        calibrationNumber: null,
        contractEndingItemNumber: null,
        drawingDescription: null,
        drawingNumber: null,
        isTool: false,
        itemClass: null,
        lastCalibrationDate: null,
        location: null,
        lotNumber: null,
        serialNumber: null,
        shelfLifeExpirationDate: null,
        side: Side.NONE,
        usageLifeExpirationDate: null
    };
    selectedPartReferenceFormData = {
        attributes: {},
        asBuiltNumber: null,
        birthDate: null,
        calibrationNumber: null,
        contractEndingItemNumber: null,
        drawingDescription: null,
        drawingNumber: null,
        isTool: false,
        itemClass: null,
        lastCalibrationDate: null,
        location: null,
        lotNumber: null,
        serialNumber: null,
        shelfLifeExpirationDate: null,
        side: undefined,
        usageLifeExpirationDate: null
    };
    borderColor;
    borderSize;
    partReferenceId;
    removeShadow;
    get hasSelectedPartReferenceBeenEdited() {
        if (!this.selectedPartReference || this.selectedPartReference.itemInstanceId) {
            return false;
        }
        return Object.keys(this.selectedPartReferenceFormData).some((key) => {
            return this.selectedPartReferenceFormData[key] !== this.selectedPartReference?.[key];
        });
    }
    async fetchPartReferenceById(id) {
        if (!id) {
            return;
        }
        try {
            const resp = await getPartReference(id);
            this.selectedPartReference = resp.data ? transformPartReferenceResponses([resp.data])[0] : null;
            this.emitPartReferenceSelected(this.selectedPartReference);
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    getTabColor(tab) {
        return this.currentTab === tab ? 'var(--v-hardware_lists-base)' : 'var(--cosmic-gray-lighten-4)';
    }
    getTabTextColor(tab) {
        return this.currentTab === tab ? 'white' : 'var(--cosmic-text)';
    }
    onClickOfClearSelection() {
        this.selectedPartReference = null;
        this.currentTab = PartReferenceTab.PART_REFERENCE_SEARCH;
    }
    onClickOfUndoEditFormChanges() {
        Object.keys(this.selectedPartReferenceFormData).forEach((key) => {
            this.selectedPartReferenceFormData[key] = this.selectedPartReference?.[key];
        });
        this.emitPartReferenceSelected(this.selectedPartReference);
    }
    emitEditedPartReferenceSelected(partReference) { }
    emitNewPartReferenceRequestSelected(partReferenceRequest) { }
    emitPartReferenceSelected(partReference) { }
    onChangeOfPartReferenceId(id) {
        if (!id) {
            return;
        }
        this.fetchPartReferenceById(id);
        this.currentTab = PartReferenceTab.SELECTED_PART_REFERENCE;
    }
    onChangeOfNewPartReferenceFormData(partReferenceRequest) {
        if (partReferenceRequest) {
            this.emitNewPartReferenceRequestSelected(partReferenceRequest);
            this.emitPartReferenceSelected(null);
            this.emitEditedPartReferenceSelected(null);
            this.currentTab = PartReferenceTab.NEW_PART_REFERENCE;
            return;
        }
        this.emitNewPartReferenceRequestSelected(null);
    }
    // This represents the user selecting an existing part reference from the search results
    onChangeOfSelectedPartReference(selectedPartReference) {
        if (selectedPartReference) {
            this.emitPartReferenceSelected(selectedPartReference);
            this.emitNewPartReferenceRequestSelected(null);
            this.emitEditedPartReferenceSelected(null);
            // if it’s a non-II part reference, populate the edit form data in case
            // the user wants to make changes
            if (!selectedPartReference.itemInstanceId) {
                Object.keys(this.selectedPartReferenceFormData).forEach((key) => {
                    this.selectedPartReferenceFormData[key] = selectedPartReference[key];
                });
            }
            this.currentTab = PartReferenceTab.SELECTED_PART_REFERENCE;
            return;
        }
        this.emitPartReferenceSelected(null);
    }
    // Represents the user making changes to an existing part reference
    onChangeOfSelectedPartReferenceFormData(selectedPartReferenceFormData) {
        if (selectedPartReferenceFormData && this.hasSelectedPartReferenceBeenEdited) {
            // we combine the existing part reference with the user's changes
            const partReference = Object.assign({}, this.selectedPartReference, selectedPartReferenceFormData);
            this.emitEditedPartReferenceSelected(partReference);
            this.emitPartReferenceSelected(null);
            this.emitNewPartReferenceRequestSelected(null);
            return;
        }
        this.emitEditedPartReferenceSelected(null);
    }
    /**
     * When we switch "tabs," emit the appropriate part reference model.
     * This will be one of three possibilities:
     *
     * - The user has selected a new part reference to create
     * - The user has selected an existing part reference unedited
     * - The user has selected an existing part reference and made changes
     */
    onChangeOfShowPartReferenceRequestForm(currentTab) {
        if (currentTab === PartReferenceTab.NEW_PART_REFERENCE) {
            this.emitNewPartReferenceRequestSelected(this.newPartReferenceFormData);
            this.emitPartReferenceSelected(null);
            this.emitEditedPartReferenceSelected(null);
            return;
        }
        if (currentTab === PartReferenceTab.SELECTED_PART_REFERENCE && !this.hasSelectedPartReferenceBeenEdited) {
            this.emitPartReferenceSelected(this.selectedPartReference);
            this.emitNewPartReferenceRequestSelected(null);
            this.emitEditedPartReferenceSelected(null);
            return;
        }
        if (currentTab === PartReferenceTab.SELECTED_PART_REFERENCE && this.hasSelectedPartReferenceBeenEdited) {
            const partReference = Object.assign({}, this.selectedPartReference, this.selectedPartReferenceFormData);
            this.emitEditedPartReferenceSelected(partReference);
            this.emitPartReferenceSelected(null);
            this.emitNewPartReferenceRequestSelected(null);
            return;
        }
    }
};
__decorate([
    Prop({
        default: 'var(--v-hardware_lists-base)',
        type: String
    }),
    __metadata("design:type", String)
], SelectorPartReference.prototype, "borderColor", void 0);
__decorate([
    Prop({
        default: '2px',
        type: String
    }),
    __metadata("design:type", String)
], SelectorPartReference.prototype, "borderSize", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], SelectorPartReference.prototype, "partReferenceId", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorPartReference.prototype, "removeShadow", void 0);
__decorate([
    Emit('edited-part-reference-selected'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], SelectorPartReference.prototype, "emitEditedPartReferenceSelected", null);
__decorate([
    Emit('new-part-reference-selected'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], SelectorPartReference.prototype, "emitNewPartReferenceRequestSelected", null);
__decorate([
    Emit('part-reference-selected'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], SelectorPartReference.prototype, "emitPartReferenceSelected", null);
__decorate([
    Watch('partReferenceId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object]),
    __metadata("design:returntype", void 0)
], SelectorPartReference.prototype, "onChangeOfPartReferenceId", null);
__decorate([
    Watch('newPartReferenceFormData', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_f = typeof Maybe !== "undefined" && Maybe) === "function" ? _f : Object]),
    __metadata("design:returntype", void 0)
], SelectorPartReference.prototype, "onChangeOfNewPartReferenceFormData", null);
__decorate([
    Watch('selectedPartReference'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_g = typeof Maybe !== "undefined" && Maybe) === "function" ? _g : Object]),
    __metadata("design:returntype", void 0)
], SelectorPartReference.prototype, "onChangeOfSelectedPartReference", null);
__decorate([
    Watch('selectedPartReferenceFormData', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_h = typeof Maybe !== "undefined" && Maybe) === "function" ? _h : Object]),
    __metadata("design:returntype", void 0)
], SelectorPartReference.prototype, "onChangeOfSelectedPartReferenceFormData", null);
__decorate([
    Watch('currentTab'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], SelectorPartReference.prototype, "onChangeOfShowPartReferenceRequestForm", null);
SelectorPartReference = __decorate([
    Component
], SelectorPartReference);
export default SelectorPartReference;
