import { ActivityTypeDisplay } from '../enums/activity';
import { TransactionTypeDisplay } from '../enums/assembly';
import { ChangeSetTypeDisplay } from '../enums/change-set';
import { CommentTypeDisplay } from '../enums/comment';
import { DocumentTypeDisplay } from '../enums/document';
import { EquipmentGroupDisplay } from '../enums/equipment-group';
import { EventTypeDisplay } from '../enums/event';
import { HardwareListTypeDisplay, InventoryClassDisplay } from '../enums/hardware';
import { OrganizationTypeDisplay } from '../enums/organization';
import { PhysicalInventoryAuditTypeDisplay } from '../enums/physical-inv-audit';
export var EntityType;
(function (EntityType) {
    EntityType["ACTIVITY"] = "activities";
    EntityType["APPROVAL_ROUTING_TEMPLATE"] = "approval_routing_templates";
    EntityType["ASSEMBLY"] = "assemblies";
    EntityType["ASSEMBLY_PROJECTION"] = "assembly_projections";
    EntityType["ASSEMBLY_TEMPLATE"] = "assembly_templates";
    EntityType["ASSEMBLY_TRANSACTION"] = "assembly_transactions";
    EntityType["CHANGE_SET"] = "change_sets";
    EntityType["COMMENT"] = "comments";
    EntityType["CONTACT"] = "contacts";
    EntityType["CONTRACT"] = "contracts";
    EntityType["CREW"] = "crews";
    EntityType["DELIVERABLE"] = "deliverables";
    EntityType["DELIVERY_MATRIX_ITEM"] = "delivery_matrix_items";
    EntityType["DIGITAL_RECORD"] = "digital_records";
    EntityType["DOCUMENT"] = "documents";
    EntityType["EVENT"] = "events";
    EntityType["HARDWARE_LIST"] = "hardware_lists";
    EntityType["HARDWARE_LIST_TEMPLATE"] = "hardware_list_templates";
    EntityType["INVENTORY"] = "inventories";
    EntityType["ITEM_DRAWING"] = "item_drawings";
    EntityType["ITEM_INSTANCE"] = "item_instances";
    EntityType["ITEM_MASTER"] = "item_masters";
    EntityType["JF288"] = "jf288";
    EntityType["LOCATION"] = "locations";
    EntityType["MEASUREMENT"] = "measurements";
    EntityType["ORGANIZATION"] = "organizations";
    EntityType["PHYSICAL_INVENTORY_AUDIT"] = "physical_inventory_audits";
    EntityType["PRODUCT_BREAKDOWN_STRUCTURE"] = "product_breakdown_structures";
    EntityType["RISK"] = "risks";
    EntityType["RESPONSIBLE_ORGANIZATION"] = "responsible_organizations";
    EntityType["TRANSACTION"] = "transactions";
    EntityType["USER"] = "users";
    EntityType["USER_GROUP"] = "user_groups";
    EntityType["VEHICLE"] = "vehicles";
    EntityType["WORKING_ASSEMBLY"] = "working_assemblies";
    EntityType["WORKLIST"] = "worklists";
})(EntityType || (EntityType = {}));
export const EntityTypeSingularDisplay = new Map([
    [EntityType.ACTIVITY, 'Activity'],
    [EntityType.APPROVAL_ROUTING_TEMPLATE, 'Approval Routing Template'],
    [EntityType.ASSEMBLY, 'Assembly'],
    [EntityType.ASSEMBLY_PROJECTION, 'Assembly Projection'],
    [EntityType.ASSEMBLY_TEMPLATE, 'Assembly Template'],
    [EntityType.ASSEMBLY_TRANSACTION, 'Assembly Transaction'],
    [EntityType.CHANGE_SET, 'Change Set'],
    [EntityType.COMMENT, 'Comment'],
    [EntityType.CONTACT, 'Contact'],
    [EntityType.CONTRACT, 'Contract'],
    [EntityType.CREW, 'Crew'],
    [EntityType.DELIVERY_MATRIX_ITEM, 'Delivery Matrix item'],
    [EntityType.DIGITAL_RECORD, 'Digital Record'],
    [EntityType.DOCUMENT, 'Document'],
    [EntityType.EVENT, 'Event'],
    [EntityType.HARDWARE_LIST, 'Hardware List'],
    [EntityType.HARDWARE_LIST_TEMPLATE, 'Hardware List Template'],
    [EntityType.INVENTORY, 'Inventory'],
    [EntityType.ITEM_DRAWING, 'Item Drawing'],
    [EntityType.ITEM_INSTANCE, 'Item Instance'],
    [EntityType.ITEM_MASTER, 'Item Master'],
    [EntityType.JF288, 'JF288'],
    [EntityType.LOCATION, 'Location'],
    [EntityType.MEASUREMENT, 'Measurement'],
    [EntityType.ORGANIZATION, 'Organization'],
    [EntityType.PHYSICAL_INVENTORY_AUDIT, 'Physical Inventory Audit'],
    [EntityType.PRODUCT_BREAKDOWN_STRUCTURE, 'PBS'],
    [EntityType.RESPONSIBLE_ORGANIZATION, 'Responsible Organization'],
    [EntityType.RISK, 'Risk'],
    [EntityType.TRANSACTION, 'Transaction'],
    [EntityType.USER_GROUP, 'User Group'],
    [EntityType.USER, 'User'],
    [EntityType.VEHICLE, 'Vehicle'],
    [EntityType.WORKLIST, 'Worklist']
]);
export const EntityTypePluralDisplay = new Map([
    [EntityType.ACTIVITY, 'Activities'],
    [EntityType.APPROVAL_ROUTING_TEMPLATE, 'Approval Routing Templates'],
    [EntityType.ASSEMBLY, 'Assemblies'],
    [EntityType.ASSEMBLY_PROJECTION, 'Assembly Projections'],
    [EntityType.ASSEMBLY_TEMPLATE, 'Assembly Templates'],
    [EntityType.ASSEMBLY_TRANSACTION, 'Assembly Transactions'],
    [EntityType.CHANGE_SET, 'Change Sets'],
    [EntityType.COMMENT, 'Comments'],
    [EntityType.CONTACT, 'Contacts'],
    [EntityType.CONTRACT, 'Contracts'],
    [EntityType.CREW, 'Crews'],
    [EntityType.DELIVERY_MATRIX_ITEM, 'Delivery Matrix items'],
    [EntityType.DIGITAL_RECORD, 'Digital Records'],
    [EntityType.DOCUMENT, 'Documents'],
    [EntityType.EVENT, 'Events'],
    [EntityType.HARDWARE_LIST, 'Hardware Lists'],
    [EntityType.HARDWARE_LIST_TEMPLATE, 'Hardware List Templates'],
    [EntityType.INVENTORY, 'Inventories'],
    [EntityType.ITEM_DRAWING, 'Item Drawings'],
    [EntityType.ITEM_INSTANCE, 'Item Instances'],
    [EntityType.ITEM_MASTER, 'Item Masters'],
    [EntityType.JF288, 'JF288s'],
    [EntityType.LOCATION, 'Locations'],
    [EntityType.MEASUREMENT, 'Measurements'],
    [EntityType.ORGANIZATION, 'Organizations'],
    [EntityType.PHYSICAL_INVENTORY_AUDIT, 'Physical Inventory Audits'],
    [EntityType.PRODUCT_BREAKDOWN_STRUCTURE, 'PBSs'],
    [EntityType.RESPONSIBLE_ORGANIZATION, 'Responsible Organizations'],
    [EntityType.RISK, 'Risks'],
    [EntityType.USER_GROUP, 'User Groups'],
    [EntityType.USER, 'Users'],
    [EntityType.VEHICLE, 'Vehicles'],
    [EntityType.WORKLIST, 'Worklists']
]);
export const EntityTypeDisplayMap = new Map([
    [EntityType.ACTIVITY, ActivityTypeDisplay],
    [EntityType.ASSEMBLY_TRANSACTION, TransactionTypeDisplay],
    [EntityType.CHANGE_SET, ChangeSetTypeDisplay],
    [EntityType.COMMENT, CommentTypeDisplay],
    [EntityType.DOCUMENT, DocumentTypeDisplay],
    [EntityType.EVENT, EventTypeDisplay],
    [EntityType.HARDWARE_LIST, HardwareListTypeDisplay],
    [EntityType.INVENTORY, InventoryClassDisplay],
    [EntityType.ITEM_INSTANCE, InventoryClassDisplay],
    [EntityType.ITEM_MASTER, EquipmentGroupDisplay],
    [EntityType.ORGANIZATION, OrganizationTypeDisplay],
    [EntityType.PHYSICAL_INVENTORY_AUDIT, PhysicalInventoryAuditTypeDisplay]
]);
